import React, {useCallback, useState} from "react";
import {Carousel as CarouselLocal} from "react-responsive-carousel";
import CarouselModal, {Modal, ModalGateway} from "react-images";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function Carousel({images}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const openLightbox = useCallback(index => {
    setCurrentImage(index);
    setIsModalOpen(true);
  }, []);

  const closeLightbox = useCallback(() => {
    setCurrentImage(0);
    setIsModalOpen(false);
  }, []);

  if (!Array.isArray(images)) return null;

  return (
    <div>
      <CarouselLocal
        showThumbs={false}
        onClickItem={openLightbox}
        interval={4000}
      >
        {images.map((src, ix) => (
          <div
            className="project-image"
            style={{backgroundImage: `url("${src}")`}}
            key={ix}
          />
        ))}
      </CarouselLocal>
      <ModalGateway>
        {isModalOpen ? (
          <Modal onClose={closeLightbox}>
            <CarouselModal
              views={images.map(source => ({source}))}
              currentIndex={currentImage}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default Carousel;
